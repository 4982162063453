var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("m-base-card", {
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(
              " " +
                _vm._s(`${_vm.course.course_key}: ${_vm.course.course_title}`) +
                " "
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "col-1",
        fn: function () {
          return [
            _c("div", [
              _c("p", { staticClass: "text-center my-3" }, [
                _vm._v("Started from: " + _vm._s(_vm.course.begin_date)),
              ]),
              _c("p", { staticClass: "text-center my-3" }, [
                _vm._v("Ends at: " + _vm._s(_vm.course.end_date)),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "col-2",
        fn: function () {
          return [
            _c(
              "v-row",
              [
                _c("v-col", [
                  _c(
                    "p",
                    {
                      staticClass: "mb-0",
                      staticStyle: { "font-size": "10px" },
                    },
                    [_vm._v("BATCH")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "font-weight-bold",
                      staticStyle: { "font-size": "14px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.course.batch_title) + " ")]
                  ),
                ]),
                _c("v-col", [
                  _c(
                    "p",
                    {
                      staticClass: "mb-0",
                      staticStyle: { "font-size": "10px" },
                    },
                    [_vm._v("INSTRUCTOR")]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "font-weight-bold",
                      staticStyle: { "font-size": "14px" },
                    },
                    [_vm._v(" " + _vm._s(_vm.course.teacher_name) + " ")]
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "col-3",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex justify-center align-center" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "text--color",
                    staticStyle: { "text-decoration": "underline" },
                    attrs: {
                      to: `/courses/${_vm.course.course_id}/${_vm.course.batch_id}`,
                    },
                  },
                  [_vm._v(" DETAILS ")]
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }