var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    [
      _c("PageTitle", {
        attrs: { "page-title": "My Courses" },
        scopedSlots: _vm._u([
          {
            key: "action",
            fn: function () {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "hidden-sm-and-down",
                    attrs: {
                      color: "primary",
                      dark: "",
                      depressed: "",
                      rounded: "",
                      to: "/announcements",
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }),
                    _vm._v("Announcement"),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "hidden-sm-and-down",
                    attrs: {
                      color: "primary",
                      dark: "",
                      depressed: "",
                      rounded: "",
                      to: "/browse-courses",
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }),
                    _vm._v("Announcement"),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "hidden-sm-and-down",
                    attrs: {
                      color: "primary",
                      dark: "",
                      depressed: "",
                      rounded: "",
                      to: "/browse-courses",
                    },
                  },
                  [
                    _c("v-icon", { attrs: { left: "" } }, [_vm._v("search")]),
                    _vm._v("Browse Courses"),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    staticClass: "hidden-md-and-up",
                    attrs: {
                      fab: "",
                      color: "primary",
                      depressed: "",
                      small: "",
                      to: "/browse-courses",
                    },
                  },
                  [_c("v-icon", [_vm._v("search")])],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "lazy-load",
        { attrs: { item: _vm.courses } },
        _vm._l(_vm.courses, function (course) {
          return _c(
            "v-container",
            {
              key: course.course_id + "#" + course.batch_id,
              staticClass: "mb-5",
            },
            [_c("m-course-card", { attrs: { course: course } })],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }