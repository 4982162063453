<template>
  <v-container>
    <PageTitle page-title="My Courses">
      <template v-slot:action>
        <v-btn
          color="primary"
          dark
          depressed
          rounded
          to="/announcements"
          class="hidden-sm-and-down"
          ><v-icon left></v-icon>Announcement</v-btn
        >
        <v-btn
          color="primary"
          dark
          depressed
          rounded
          to="/browse-courses"
          class="hidden-sm-and-down"
          ><v-icon left></v-icon>Announcement</v-btn
        >
        <v-btn
          color="primary"
          dark
          depressed
          rounded
          to="/browse-courses"
          class="hidden-sm-and-down"
          ><v-icon left>search</v-icon>Browse Courses</v-btn
        >
        <v-btn
          fab
          color="primary"
          class="hidden-md-and-up"
          depressed
          small
          to="/browse-courses"
          ><v-icon>search</v-icon></v-btn
        >
      </template>
    </PageTitle>
    <lazy-load :item="courses">
      <v-container
        v-for="course in courses"
        :key="course.course_id + '#' + course.batch_id"
        class="mb-5"
      >
        <m-course-card :course="course"></m-course-card>
      </v-container>
    </lazy-load>
  </v-container>
</template>

<script>
import PageTitle from "@ef/global/components/PageTitle";
import courseService from "#ef/course/services/CourseService";
import MCourseCard from "#ef/course/components/MCourseCard";

export default {
  name: "MCourses",
  components: { MCourseCard, PageTitle },
  data: () => ({
    courses: undefined,
    fetchingCourses: false,
  }),
  created() {
    this.fetchCourses();
  },
  methods: {
    async fetchCourses() {
      const isOnline = window.navigator.onLine;
      if ( !isOnline ){
        this.$root.$emit("alert", ["Alert", "There might be an internet connection issue on your system"])
        return;
      }
      try {
        this.fetchingCourses = true;
        this.courses = await courseService.find();
        this.courses.forEach(c => {
          if (c.days === undefined) c.days = [];
          c.days = c.days.map(s => {
            const parts = s.split("#");
            return `${parts[0]}: ${parts[1]}-${parts[2]}`;
          });
        });
      } catch (e) {
        this.$root.$emit("alert", [undefined, e.message]);
      } finally {
        this.fetchingCourses = false;
      }
    },
  },
};
</script>
