<template>
  <m-base-card>
    <template v-slot:title>
      {{ `${course.course_key}: ${course.course_title}` }}
    </template>
    <template v-slot:col-1>
      <div>
        <p class="text-center my-3">Started from: {{ course.begin_date }}</p>
        <p class="text-center my-3">Ends at: {{ course.end_date }}</p>
      </div>
    </template>
    <template v-slot:col-2>
      <v-row>
        <v-col>
          <p class="mb-0" style="font-size: 10px">BATCH</p>
          <p style="font-size: 14px" class="font-weight-bold">
            {{ course.batch_title }}
          </p>
        </v-col>
        <v-col>
          <p class="mb-0" style="font-size: 10px">INSTRUCTOR</p>
          <p style="font-size: 14px" class="font-weight-bold">
            {{ course.teacher_name }}
          </p>
        </v-col>
      </v-row>
    </template>
    <template v-slot:col-3>
      <div class="d-flex justify-center align-center">
        <router-link
          :to="`/courses/${course.course_id}/${course.batch_id}`"
          style="text-decoration: underline"
          class="text--color"
        >
          DETAILS
        </router-link>
      </div>
    </template>
  </m-base-card>
</template>

<script>
import MBaseCard from "~ef/components/MBaseCard";
export default {
  name: "MCourseCard",
  components: { MBaseCard },
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
